<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <!-- <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Add New User") }}</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div> -->
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" :name="$t('Full Name')" rules="required">
            <b-form-group :label="$t('Name and Surname')" label-for="name">
              <b-form-input id="name" v-model="userData.name" autofocus :state="getValidationState(validationContext)"
                :placeholder="$t('Subject Name and Surname')" ref="formName" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Age -->
          <validation-provider #default="validationContext" :name="$t('Birth Date')" ref="birthdayValidator" :rules="`required|date_between:${
              new Date(Date.now() - (4 * 365 + 1) * 24 * 60 * 60 * 1000)
                .toISOString()
                .split('T')[0]
            },${
              new Date(Date.now() - (18 * 365 + 4.5) * 24 * 60 * 60 * 1000)
                .toISOString()
                .split('T')[0]
            }`">
            <b-form-group :label="$t('Birth Date')" label-for="birthday">
              <b-form-datepicker id="birthday" v-model="userData.birthday" autofocus locale="tr"
                :state="getValidationState(validationContext)" trim placeholder="0" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Gender -->
          <validation-provider #default="validationContext" :name="$t('gender')" rules="required">
            <b-form-group :label="$t('Gender')" label-for="gender">
              <b-form-select id="gender" v-model="userData.gender" autofocus
                :state="getValidationState(validationContext)" trim :placeholder="$t('Gender')" :options="genderOptions">
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" :name="$t('phone')" rules="integer|required|min:10">
            <b-form-group :label="$t('Phone')" label-for="phone">
              <b-form-input id="phone" v-model="userData.guardian[0].phone" autofocus
                :state="getValidationState(validationContext)" trim maxlength="12" :placeholder="$t('Input Phone')" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormDatepicker,
} from "bootstrap-vue";
import { localize, ValidationProvider, ValidationObserver } from "vee-validate";
import tr from "vee-validate/dist/locale/tr.json";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, date_between } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
// console.log(localize);
localize({
  tr,
});
export default {
  components: {
    BSidebar,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      date_between,
      countries,
      genderOptions: [
        { value: null, text: "Cinsiyet" },
        { value: "Kız", text: "Kız" },
        { value: "Erkek", text: "Erkek" },
      ],
      selected: null,
    };
  },
  setup(props, { emit }) {
    localize("tr");
    const blankUserData = {
      name: "",
      age: "",
      birthday: new Date(),
      guardian: [{ phone: "" }],
      gender: null,
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      //Yas siniri kontrolu icin haber bekleniyor.

      //let userAge = Math.r((Date.now() - new Date(userData.value.birthday).getTime()) / (1000 * 60 * 60 * 24 * 365)) ;
      //if()
      if (!userData.value.age && userData.value.birthday) {
        console.log(userData.value.birthday);
        userData.value.age = Math.floor(
          (Date.now() - new Date(userData.value.birthday).getTime()) /
            (1000 * 60 * 60 * 24 * 365)
        );
      }
      if (!(userData.value.age >= 4 && userData.value.age <= 17)) {
        alert("Yaş sınırı 4 ile 18 arasında olmalıdır.");
        this.$refs.birthdayError = "Yaş sınırı 4 ile 18 arasında olmalıdır.";
        return false;
      }
      store.dispatch("app-user/addUser", userData.value).then((resp) => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
        this.$route.push(`/clientlist/${resp.data.subject_id}`);
      });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
