<template>
  <div>
    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />
    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <!-- Table Container Card -->
    
    <b-card no-body class="mb-0" id="subject-table">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button id="add-new-user-button" variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">{{ $t("Add Client") }}</span>
              </b-button>
              <b-form-input v-model="searchQuery" class="d-inline-block ml-1" :placeholder="$t('Client Search')" />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("Entry") + "i " + $t("Show") }}</label>
            <!-- <label>{{ " " + $t("Show") }}</label> -->
          </b-col>
        </b-row>
      </div>
      <b-table ref="refUserListTable" class="position-relative" :items="fetchUsers" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc" :per-page="perPage" :current-page="currentPage">
        <!--:filterByFormatted="$can('read', `${data.item.isFirmSubject ? 'F' : 'S'}.Subject`)"-->
        <!-- Column: User -->
        <template #cell(name)="data" :filterByFormatted="
              $can('read', `${data.item.isFirmSubject ? 'F' : 'S'}.Subject`)
            ">
          <b-media vertical-align="center" :id="data.item._id">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :variant="`light-${resolveUserRoleVariant(
                    numberToGrade(
                      Math.round(
                        (data.item.program.groups.concentration.group +
                          data.item.program.groups.attention.group +
                          data.item.program.groups.logic.group +
                          data.item.program.groups.memory.group) /
                          4
                      )
                    )
                  )}`" :to="{
                    name: 'clientlist-detail',
                    params: { id: data.item._id },
                  }" />
            </template>
            <b-link :to="{ name: 'clientlist-detail', params: { id: data.item._id } }" :class="`font-weight-bold d-block center text-nowrap text-${resolveUserRoleVariant(
                  numberToGrade(
                    Math.round(
                      (data.item.program.groups.concentration.group +
                        data.item.program.groups.attention.group +
                        data.item.program.groups.logic.group +
                        data.item.program.groups.memory.group) /
                        4
                    )
                  )
                )}`" class="d-flex align-middle justify-content-start" @click="logUserID(data.item._id)">
              {{ data.item.name }}
            </b-link>
            <!-- <small class="text-muted">@{{ data.item.username }}</small> -->
          </b-media>
        </template>
        <!-- Column: Phone -->
        <template #cell(phone)="data">
          <div class="text-nowrap">
            <a v-if="data.item.guardian[0] && data.item.guardian[0].phone" :href="`tel:${data.item.guardian[0].phone}`">
              <feather-icon size="18" :icon="resolveUserRoleIcon('phone')" class="mr-50 text-success" />
              <span class="align-text-top text-capitalize">{{ data.item.guardian[0].phone
                }}</span>
            </a>
            <span v-else>
              <feather-icon size="18" :icon="resolveUserRoleIcon('phone')" class="mr-50 text-danger" />
              <span class="align-text-top text-capitalize">{{ $t("No phone number")
                }}</span>
            </span>
          </div>
        </template>
        <!-- Column: Role -->
        <!-- <feather-icon size="18"
          :icon="resolveUserRoleIcon(data.item.subjectClass)"
          class="mr-50" /> -->
        <template #cell(attention)="data">
          <!--<div class="text-nowrap">
              <span
              :class="`text-${resolveUserRoleVariant(data.item.subjectClass)} align-text-top text-capitalize
              `"
              >{{ data.item.subjectClass }}</span
              >
            </div> -->
          <b-badge v-if="data.item.program.groups.attention.group" :variant="
                resolveUserRoleVariant(
                  numberToGrade(data.item.program.groups.attention.group)
                )
              ">
            <span class="text-nowrap uid-text">{{ numberToGrade(data.item.program.groups.attention.group)
              }}</span>
            <feather-icon v-if="
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] > data.item.program.groups.attention.group
                " icon="ArrowUpRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] < data.item.program.groups.attention.group
                " icon="ArrowDownRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.attention.change[
                    data.item.program.groups.attention.change.length - 1
                  ] == data.item.program.groups.attention.group
                " icon="CodeIcon"></feather-icon>
          </b-badge>
          <span v-else>-</span>
        </template>
        <template #cell(logic)="data">
          <!--<div class="text-nowrap">
              <span
              :class="`text-${resolveUserRoleVariant(data.item.subjectClass)} align-text-top text-capitalize
              `"
              >{{ data.item.subjectClass }}</span
              >
            </div> -->
          <b-badge v-if="data.item.program.groups.logic.group" :variant="
                resolveUserRoleVariant(numberToGrade(data.item.program.groups.logic.group))
              ">
            <span class="text-nowrap uid-text">{{ numberToGrade(data.item.program.groups.logic.group)
              }}</span>
            <feather-icon v-if="
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] > data.item.program.groups.logic.group
                " icon="ArrowUpRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] < data.item.program.groups.logic.group
                " icon="ArrowDownRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.logic.change[
                    data.item.program.groups.logic.change.length - 1
                  ] == data.item.program.groups.logic.group
                " icon="CodeIcon"></feather-icon>
          </b-badge>
          <span v-else>-</span>
        </template>
        <template #cell(concentration)="data">
          <!--<div class="text-nowrap">
            <span
            :class="`text-${resolveUserRoleVariant(data.item.subjectClass)} align-text-top text-capitalize
            `"
            >{{ data.item.subjectClass }}</span
            >
          </div> -->
          <b-badge v-if="data.item.program.groups.concentration.group" :variant="
                resolveUserRoleVariant(
                  numberToGrade(data.item.program.groups.concentration.group)
                )
              ">
            <span class="text-nowrap uid-text">{{ numberToGrade(data.item.program.groups.concentration.group)
              }}</span>
            <feather-icon v-if="
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] > data.item.program.groups.concentration.group
                " icon="ArrowUpRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] < data.item.program.groups.concentration.group
                " icon="ArrowDownRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.concentration.change[
                    data.item.program.groups.concentration.change.length - 1
                  ] == data.item.program.groups.concentration.group
                " icon="CodeIcon"></feather-icon>
          </b-badge>
          <span v-else>-</span>
        </template>
        <template #cell(memory)="data">
          <!--<div class="text-nowrap">
              <span
              :class="`text-${resolveUserRoleVariant(data.item.subjectClass)} align-text-top text-capitalize
              `"
              >{{ data.item.subjectClass }}</span
            >
          </div> -->
          <b-badge v-if="data.item.program.groups.memory.group" :variant="
                resolveUserRoleVariant(numberToGrade(data.item.program.groups.memory.group))
              ">
            <span class="text-nowrap uid-text">{{ numberToGrade(data.item.program.groups.memory.group)
              }}</span>
            <feather-icon v-if="
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] > data.item.program.groups.memory.group
                " icon="ArrowUpRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] < data.item.program.groups.memory.group
                " icon="ArrowDownRightIcon"></feather-icon>
            <feather-icon v-else-if="
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] != 0 &&
                  data.item.program.groups.memory.change[
                    data.item.program.groups.memory.change.length - 1
                  ] == data.item.program.groups.memory.group
                " icon="CodeIcon"></feather-icon>
          </b-badge>
          <span v-else>-</span>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.active || false)}`" class="text-capitalize">
            {{ data.item.active ? $t("Active") : $t("Inactive") }}
          </b-badge>
        </template>
        <template #cell(program)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(
                data.item.program.active || false
              )}`" class="text-capitalize">
            {{ data.item.program.active ? $t("Active") : $t("Inactive") }}
          </b-badge>
          <!-- <b-badge
            v-if="data.item.program.active"
            pill
            style="float: left"
            :variant="`light-${
              data.item.program.change > 0
              ? 'success'
              : data.item.program.change < 0
              ? 'danger'
              : 'warning'
            }`"
            class="text-capitalize"
            >
            <feather-icon
            size="2x"
            :icon="
            data.item.changePercent == 0
            ? 'CodeIcon'
            : data.item.changePercent < 0
            ? 'ArrowDownRightIcon'
            : 'ArrowUpRightIcon'
            "
            ></feather-icon>
            {{ data.item.changePercent + " %" }}
          </b-badge> -->
        </template>
        <!-- Column: Change -->
        <template #cell(change)="data">
          <!-- <b-badge
            style="float: right"
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.program.active || false
              )}`"
              class="text-capitalize"
              >
              {{ data.item.program.active ? $t("Active") : $t("Inactive") }}
            </b-badge> -->
          <b-badge v-if="data.item.program.active" pill style="float: left" :variant="`light-${
                data.item.program.change > 0
                  ? 'success'
                  : data.item.program.change < 0
                  ? 'danger'
                  : 'warning'
              }`" class="text-capitalize">
            <feather-icon size="2x" :icon="
                  data.item.changePercent == 0
                    ? 'CodeIcon'
                    : data.item.changePercent < 0
                    ? 'ArrowDownRightIcon'
                    : 'ArrowUpRightIcon'
                "></feather-icon>
            {{ data.item.changePercent + " %" }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data" thClass="w-10">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" v-if="
                data.item.active &&
                ($can('read', `${data.item.isFirmSubject ? 'F' : 'S'}.Exercise`) ||
                  $can('update', `${data.item.isFirmSubject ? 'F' : 'S'}.Subject`))
              ">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item v-if="
                  $can('read', `${data.item.isFirmSubject ? 'F' : 'S'}.Exercise`) &&
                  data.item.active
                " :to="{ name: 'exercises-detail', params: { id: data.item._id } }" @click="logUserID(id)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t("Exercises") }}</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="
                  $can('update', `${data.item.isFirmSubject ? 'F' : 'S'}.Subject`) &&
                  data.item.active
                " :to="{ name: 'clientlist-edit', params: { id: data.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="toggleActive(data.item._id, data.item.active)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">{{ $t("Delete") }}</span>
        </b-dropdown-item> -->
          </b-dropdown>
          <div v-else v.b-tooltip.hover.top title="'İşlem Yetkiniz Yok'">
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </div>
        </template>
      </b-table>
      <b-tooltip target="subject-table-name" placement="top" triggers="hover"> tooltip! </b-tooltip>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }} {{ dataMeta.to }}
              {{ $t("of") }} {{ $t("total") }} {{ dataMeta.of }} {{ $t("entries") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
                prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";

import Ripple from "vue-ripple-directive";
// import axios from "@axios";
// const {
//   axiosRoutes: { subject: path },
// } = require("/G_CONFIG");

export default {
  props: {
    sort: {
      type: String,
    },
  },
  components: {
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      asdf: "sa selim",
      steps: [
        {
          target: "#add-new-user-button",
          header: {
            title: "Diko Öğretici- Danışan Ekle",
          },
          content:
            "Danışan Ekle butonuna tıklayarak danışanınızı hızlıca ekleyebilirsiniz.👉",
          params: {
            placement: "left",
          },
        },
        {
          target: "#subject-table",
          header: {
            title: "Diko Öğretici - Danışan Tablosu",
          },
          content: "Eklediğiniz danışanları görüntüleyebilir ve işlem yapabilirsiniz.😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='1'] ",
          header: {
            title: "Diko Öğretici - Danışan Seçme",
          },
          content:
            "Danışanınızın adına tıklayarak danışan profil ekranını gidebilirsiniz.😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='7'] ",
          header: {
            title: "Diko Öğretici - Durum",
          },
          content:
            "Danışanlarınızın aktif veya pasif durumunu gösterir. Danışanlarınızı durumlarına göre sıralayarak görüntüleyebilirsiniz.😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='8'] ",
          header: {
            title: "Diko Öğretici - Konular",
          },
          content:
            "Danışanlarınızın Diko GBT uygulanıp programa başlayıp başlamadığını gösterir. Diko GBT uygulanan danışanlarınız aktif olur. Danışanınızın Durum sütunu aktif ve Program sütunu pasif ise bu danışanınızın hali hazırda Diko GBT'sinin uygulanmadığını gösterir.😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='3'] ",
          header: {
            title: "Diko Öğretici - Dikkat",
          },
          content:
            "Dikkat sütununda danışanınızın seans sonuçlarına göre, Dikkat Grubu'nu gösterir😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='4'] ",
          header: {
            title: "Diko Öğretici - Mantık",
          },
          content:
            "Mantık sütununda danışanınızın seans sonuçlarına göre, Mantık Grubu'nu gösterir😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='5'] ",
          header: {
            title: "Diko Öğretici - Konsantrasyon",
          },
          content:
            "Konsantrasyon sütununda danışanınızın seans sonuçlarına göre, Konsantrasyon Grubu'nu gösterir😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='6'] ",
          header: {
            title: "Diko Öğretici - Hafıza",
          },
          content:
            "Hafıza sütununda danışanınızın seans sonuçlarına göre, Hafıza Grubu'nu gösterir😊",
          params: {
            placement: "top",
          },
        },

        {
          target: "[aria-rowindex='1'] > [aria-colindex='9'] ",
          header: {
            title: "Diko Öğretici - Değişim",
          },
          content:
            "Danışanlarınızın seans ilerleyişine göre genel durumundaki değişimini buradan izleyebilirsiniz. Danışanınız seanslara göre yükselebilir, aynı seviyede devam edebilir veya düşüş yaşayabilir. Detaylı bilgi için danışan sayfasındaki grafikleri kullanabilirsiniz.😊",
          params: {
            placement: "top",
          },
        },
        {
          target: "[aria-rowindex='1'] > [aria-colindex='11'] ",
          header: {
            title: "Diko Öğretici - Hızlı İşlem Menüsü",
          },
          content:
            "Hızlı işlem menüsü ile danışanınızın Seans sayfasına gidebilir(Grup Belirleme Testi yapılmış ise) veya düzenleme sayfasına gidebilirsiniz.😊",
          params: {
            placement: "top",
          },
        },
      ],
    };
  },
  /* v-b-tooltip.hover
      title="Default variant"
      variant="gradient-primary" */
  created() {
    
  },
  mounted() {
    // console.log(this.$route.query.sort);
    // if (this.$route.query.sort && this.$route.query.sort == "program") {
    //   this.programFilter.value = "program";
    //   this.isSortDirDesc.value = false;
    //   fetchUsers();
    // }
    // console.log(this.fetchUsers.subjects[0]._id);
  },
  computed: {
    /* totalRowsC() {
        console.log(this.fetchUsers.lenght + "değişti");
        return this.fetchUsers.lenght;
      } */
  },
  methods: {
    
    logUserID(id) {
      console.log(id);
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      numberToGrade,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      programFilter,
      changeFilter,
    } = useUsersList(props.sort);
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      numberToGrade,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      programFilter,
      changeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
